<template>
  <div class="commodity-picking-detail">
    <div class="header">
      <div class="infomation"
           v-if="$route.params.id">
        <van-cell title="请领单号"
                  :value="detail.applyNumber" />
        <van-cell title="请领单位"
                  :value="detail.organizationName" />
        <van-cell title="申请人"
                  :value="detail.applicantName" />
        <van-cell title="提货人"
                  :value="detail.pickName" />
        <van-cell title="联系电话"
                  :value="detail.pickMobileNumber" />
        <van-cell title="签名" v-if="detail.signUrl">
          <template #extra>
            <van-button style="width:60px;" type="info" size="small" native-type="button" round @click="signUrlShow=true">查看</van-button>
          </template>
        </van-cell>
        <van-cell title="仓库"
                  :value="warehouseComputed"
                  @click="showWarehouse = true" />
        <van-popup v-model="showWarehouse"
                   round
                   position="bottom">

          <custom-popup title="仓库"
                        value-key="name"
                        :columns="warehouseColumns"
                        @cancel="showWarehouse = false"
                        @confirm="warehouseConfirm"></custom-popup>
        </van-popup>
      </div>
    </div>
    <div class="main"
         :class="{ disabled: detail.status == 'SUBMITTED' }">
      <div class="detail">
        <div class="list">
          <div class="list-item"
               v-for="(item, index) in itemsComputed"
               :key="item.id">
            <item-card :title="item.stockItemName">
              <template #header>
                <div class="header">
                  <div class="h-title">{{ item.stockItemName }}</div>
                  <div class="pick-action"
                       @click="picking(item, index)">
                    <van-icon name="cart-o" />
                  </div>
                </div>
              </template>
              <div class="basic">
                <div class="fields">
                  <div class="field">
                    <span class="field-name">商品编码:</span>
                    <span class="field-value">{{ item.code }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">规格型号:</span>
                    <span class="field-value">{{ item.specifications }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">领用数量:</span>
                    <span class="field-value">{{ item.sumClaimsApplyQuantity }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">已出库数量:</span>
                    <span class="field-value">{{ item.deliveryCount }}</span>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">计量单位:</span>
                    <span class="field-value">{{ item.measuringUnit }}</span>
                  </div>
                  <!-- <div class="field">
                    <span class="field-name">拣货状态:</span>
                    <span class="field-value">{{ item.pickStatus }}</span>
                  </div> -->
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">仓库:</span>
                    <span class="field-value">{{ mapWarehouse(item.warehouseId) }}</span>
                  </div>
                </div>
              </div>
              <div class="stock-site"
                   v-for="(site, siteIndex) in item.stockSitesCopy"
                   :key="site.id">
                <div class="fields">
                  <div class="field flex">
                    <div>
                      <span class="field-name">货位名称:</span>
                      <span class="field-value">{{ site.stockSite }}</span>
                    </div>

                    <div>
                      <span class="field-edit"
                            @click="edit(item, index, siteIndex)">编辑</span>
                      <span @click="del(site, index, siteIndex)"
                            class="field-delet">删除</span>
                    </div>
                  </div>
                </div>
                <div class="fields">
                  <div class="field">
                    <span class="field-name">已拣数量:</span>
                    <span class="field-value">{{ site.actualQuantity }}</span>
                  </div>
                </div>
                <!-- <div class="fields">
                  <div class="field">
                    <span class="field-name">拣货状态:</span>
                    <span class="field-value">{{ site.pickStatus | parseStatus }}</span>
                  </div>
                </div> -->
              </div>
            </item-card>
          </div>
        </div>
        <van-empty description="暂无数据"
                   v-if="!itemsComputed.length" />
      </div>

      <!-- 编辑物品信息弹框 -->
      <van-popup class="dialog"
                 v-model="showDialog"
                 :close-on-click-overlay="false">
        <van-form ref="dialogForm">
          <!-- <van-field v-model="dialog.stockItemName"
                     name="物品"
                     label="物品"
                     readonly /> -->
          <span class="dialog-title">{{ dialog.stockItemName }}</span>
          <van-field v-model="dialog.stockSite"
                     name="存放货位"
                     label="存放货位"
                     required
                     clickable
                     readonly
                     placeholder="请选择"
                     :disabled="operStatus == 'EDIT'"
                     @click="stockSiteClick"
                     :rules="[{ required: true, message: '存放货位不能为空' }]">
          </van-field>

          <van-field v-model="dialog.actualQuantity"
                     name="实发数量"
                     label="实发数量"
                     required
                     placeholder="请填写"
                     type="number"
                     @input="actualQuantityInput"
                     :rules="[{ required: true, message: '实发数量不能为空' }]" />
          <div class="action">
            <van-button class="btn"
                        type="info"
                        round
                        size="small"
                        @click="dialogConfirm">确定</van-button>
            <van-button class="btn"
                        type="default"
                        round
                        size="small"
                        native-type="button"
                        @click="dialogCancel">取消</van-button>
          </div>
        </van-form>
      </van-popup>

      <!-- 货位选择弹框 -->
      <van-popup v-model="showStockSite"
                 round
                 position="bottom">
        <custom-popup title="货位"
                      :columns="stockSiteColumns"
                      @cancel="showStockSite = false"
                      @confirm="stockSiteConfirm"
                      v-if="stockSiteColumns.length">
          <template #option="{option}">
            <span>{{ option.name }} ({{ option.inventoryNumber }}/{{ option.maxCapacity }})</span>
          </template>
        </custom-popup>
        <van-empty description="暂无数据"
                   v-if="!stockSiteColumns.length" />
      </van-popup>
    </div>

    <van-dialog v-model="signUrlShow" title="签名" >
      <van-image :src="detail.signUrl"  fit="contain"/>
    </van-dialog>

    <div class="footer">
      <van-button class="btn"
                  round
                  block
                  type="info"
                  native-type="button"
                  :disabled="disabled||detail.pickStatus == 'FINISHED'"
                  @click="save(1)">部分出库</van-button>
      <van-button class="btn"
                  round
                  block
                  type="info"
                  native-type="button"
                  :disabled="disabled||detail.pickStatus == 'FINISHED'"
                  @click="save(2)">出库完成</van-button>
      <van-button class="btn"
                  round
                  block
                  type="info"
                  native-type="button"
                  :disabled="disabled||detail.pickStatus == 'FINISHED'"
                  @click="save(3)">拣货暂存</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityPickingDetail.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";
import { find, assign, cloneDeep } from "lodash-es";
export default {
  name: "CommodityPickingDetail",
  data() {
    return {
      detail: {},
      showDialog: false,
      firstWarehouseId: null,
      stockDeliveryId: null,
      dialog: {
        stockItemName: "",
        stockSite: "",
        actualQuantity: ""
      },
      showWarehouse: false,
      warehouseId: null,
      warehouseColumns: [],
      showStockSite: false,
      stockSiteColumns: [],

      operStatus: "",
      disabled: false,
      signUrlShow:false
    };
  },
  computed: {
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "全部仓库";
      let item = find(this.warehouseColumns, item => item.id == this.warehouseId);
      return item ? item.name : "全部仓库";
    },
    itemsComputed() {
      if (this.warehouseId) {
        return this.detail.items.filter(item => item.warehouseId == this.warehouseId);
      } else {
        return this.detail.items ?? [];
      }
    }
  },
  methods: {
    async getClaimApplyDetail() {
      let ret = await this.$api.getClaimApplyDetail({
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      ret.data.items.forEach(item => {
        item.deliveryCount = item.deliveryCount ? item.deliveryCount : 0;
        if (item.stockSites.length) {
          let isAllPick = item.stockSites.every(item => item.pickStatus == "SUBMITTED");
          item.pickStatus = isAllPick ? "已拣货" : "未拣货";
        } else {
          item.pickStatus = "";
        }
        item.stockSites.forEach(it => {
          if (it.pickStatus == "SUBMITTED") {
            item.deliveryCount = item.deliveryCount ? item.deliveryCount + it.actualQuantity : it.actualQuantity;
          }
        });
        item.stockSitesCopy = item.stockSites
          .filter(item => item.pickStatus == "STAGING")
          .map(it => {
            it.stockItemName = item.stockItemName;
            return it;
          });
      });
      let params =ret.data;
      if(ret.data.signUrl){
        
       let res= await this.getAppendixes(decodeURIComponent(ret.data.signUrl.split('?')[0]))
       let signUrl = res.data[0]
        params ={...ret.data,signUrl}
      }
      this.detail = params;
    },
    async getAppendixes(urls) {
     return await this.$api.getAppendixFilePath({ params: { urls } });
    },
    getWarehouseStockSiteList({ stockItemId = null, warehouseId, query = "(status=SUCCESS)" }) {
      let params = {
        pageNumber: 0,
        pageSize: 1000,
        stockItemId,
        sort: encodeURI(`[{"fieldName":"code","direction":"asc"}]`)
      };
      params.query = query;
      return this.$api.getWarehouseStockSiteList({
        params,
        placeholder: {
          id: warehouseId
        }
      });
    },
    addStockDelivery(items) {
      let params = {
        deliveryType: "请领出库",
        organizationName: this.detail.organizationName,
        departmentId: null,
        departmentName: null,
        warehouseId: items[0].warehouseId,
        stockClaimsApplyId: this.$route.params.id,
        status: "STAGING",
        items
      };
      return this.$api.addStockDelivery({
        params
      });
    },
    addStockDeliveryItems() {
      let params = {
        stockItemId: this.itemsComputed[this.cIndex].stockItemId,
        stockDeliveryId: this.stockDeliveryId,
        claimsApplyQuantity: this.dialog.actualQuantity,
        deliveryQuantity: this.dialog.actualQuantity,
        status: "STAGING",
        stockSite: this.dialog.stockSite,
        warehouseStockRackId: this.dialog.warehouseStockRackId
      };
      return this.$api.addStockDeliveryItems({ params });
    },
    setStockDeliveryItemStatus(ids, status) {
      let params = {
        ids,
        status
      };
      return this.$api.setStockDeliveryItemStatus({ params });
    },
    setStockDeliveryStatus(ids, status) {
      let params = {
        ids,
        status
      };
      return this.$api.setStockDeliveryStatus({ params });
    },
    setClaimsApplyPicking(status) {
      let params = {
        pickStatus: status
      };
      return this.$api.setClaimsApplyPicking({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },

    async getWarehouseList() {
      let params = {
        useType: "WAREHOUSE_KEEPER"
        // useType: "WAREHOUSE_MANAGER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = [{ id: "all", name: "全部仓库" }, ...ret.data];
    },
    warehouseConfirm(arg) {
      this.warehouseId = arg.id == "all" ? null : arg.id;
      this.showWarehouse = false;
    },

    async picking(item, index) {
      if (this.detail.pickStatus == "FINISHED") return;
      if (this.firstWarehouseId && item.warehouseId != this.firstWarehouseId) {
        this.$utils.warning("不同仓库物品请分开出库单出库");
        return;
      }
      this.dialog.stockItemName = item.stockItemName;
      this.operStatus = "NEW";
      this.cIndex = index;
      let ret = await this.getWarehouseStockSiteList({ stockItemId: item.stockItemId, warehouseId: item.warehouseId });
      if (ret.code != SUCCESS_CODE) return;
      if (item.stockSites.filter(item => item.pickStatus == "STAGING").length == ret.data.length) {
        this.$utils.warning("货位已选满");
        return;
      }
      this.stockSiteColumns = ret.data;
      this.disabledStockSite(item);
      this.stockSiteColumns.some(item => {
        if (!item.disabled) {
          this.dialog.stockSite = item.name;
          this.dialog.warehouseStockRackId = item.id;
          return true;
        }
        return false;
      });
      this.showDialog = true;
    },
    disabledStockSite(item) {
      this.stockSiteColumns.forEach(it => {
        let site = item.stockSites.find(site => site.warehouseStockRackId == it.id && site.pickStatus == "STAGING");
        if (site) {
          this.$set(it, "disabled", true);
        }
      });
    },
    async edit(stockItem, stockIndex, siteIndex) {
      if (this.detail.pickStatus == "FINISHED") return;
      if (this.firstWarehouseId && stockItem.warehouseId != this.firstWarehouseId) {
        // this.$utils.warning("不同仓库物品请分开出库单出库");
        return;
      }
      this.operStatus = "EDIT";
      this.cIndex = stockIndex;
      this.sIndex = siteIndex;
      let stockItemId = stockItem.stockItemId;
      let warehouseId = stockItem.warehouseId;
      let ret = await this.getWarehouseStockSiteList({ warehouseId, stockItemId });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteColumns = ret.data;
      // Object.assign(this.dialog, stockItem);
      this.dialog.stockItemName = stockItem.stockItemName;
      this.dialog.actualQuantity = stockItem.stockSitesCopy[siteIndex].actualQuantity;
      this.dialog.stockSite = stockItem.stockSitesCopy[siteIndex].stockSite;
      this.dialog.warehouseStockRackId = stockItem.stockSitesCopy[siteIndex].warehouseStockRackId;

      this.showDialog = true;
    },
    // 删除
    del(siteItem, stockIndex, siteIndex) {
      if (this.detail.pickStatus == "FINISHED") return;
      if (this.firstWarehouseId && this.detail.items[stockIndex].warehouseId != this.firstWarehouseId) {
        // this.$utils.warning("不同仓库物品请分开出库单出库");
        return;
      }
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let ret = await this.deleteStockDeliveryItems([siteItem.id]);
          if (ret.code != SUCCESS_CODE) return;
          this.itemsComputed[stockIndex].stockSites.splice(siteIndex, 1);
          // 详情刷新
          this.getClaimApplyDetail();
        })
        .catch(() => {});
    },

    deleteStockDeliveryItems(ids) {
      return this.$api.deleteStockDeliveryItems({
        placeholder: {
          ids
        }
      });
    },

    stockSiteClick() {
      if (this.operStatus == "EDIT") return false;
      this.showStockSite = true;
    },
    stockSiteConfirm(arg) {
      this.dialog.stockSite = arg.name;
      this.dialog.inventoryNumber = arg.inventoryNumber;
      this.dialog.warehouseStockRackId = arg.id;
      this.dialog.actualQuantity = "";
      this.showStockSite = false;
    },
    actualQuantityInput(value) {
      if (!this.dialog.stockSite) {
        this.dialog.actualQuantity = "";
        return this.$utils.warning("请先选择货位");
      }
      value = value.replaceAll(/^0+/g, "");
      this.dialog.actualQuantity = value;
      if (value > this.dialog.inventoryNumber) {
        this.dialog.actualQuantity = "";
        return this.$utils.warning("实发数量不可大于库存数量");
      }
    },
    dialogConfirm() {
      this.$refs.dialogForm
        .validate()
        .then(async () => {
          let curCommodity = this.itemsComputed[this.cIndex];
          if (this.operStatus == "NEW") {
            let ret;
            if (this.stockDeliveryId) {
              ret = await this.addStockDeliveryItems();
              if (ret.code != SUCCESS_CODE) return;
            } else {
              let items = [
                {
                  stockItemId: this.itemsComputed[this.cIndex].stockItemId,
                  deliveryQuantity: this.dialog.actualQuantity,
                  status: "STAGING",
                  stockSite: this.dialog.stockSite,
                  warehouseStockRackId: this.dialog.warehouseStockRackId,
                  warehouseId: this.itemsComputed[this.cIndex].warehouseId
                }
              ];
              ret = await this.addStockDelivery(items);
              if (ret.code != SUCCESS_CODE) return;
              this.stockDeliveryId = ret.data.id;
            }

            let stockSite = {
              stockSite: this.dialog.stockSite,
              actualQuantity: this.dialog.actualQuantity,
              stockDeliveryId: this.stockDeliveryId,
              warehouseStockRackId: this.dialog.warehouseStockRackId
            };
            curCommodity.stockSites.push(stockSite);
            curCommodity.sumActualQuantity += +stockSite.actualQuantity;
            curCommodity.pickStatus = "未拣货";
            this.dialog = cloneDeep(this.dialogCopy);
            this.$refs.dialogForm.resetValidation();
            this.showDialog = false;
            if (!this.firstWarehouseId) {
              this.firstWarehouseId = curCommodity.warehouseId;
            }
          } else if (this.operStatus == "EDIT") {
            let item = curCommodity.stockSitesCopy[this.sIndex];
            let ret = await this.setStockDeliveryItems(item);
            if (ret.code != SUCCESS_CODE) return;
            this.showDialog = false;
          }
          // 详情刷新
          this.getClaimApplyDetail();
        })
        .catch(() => {});
    },
    setStockDeliveryItems(item) {
      let params = {
        stockItemId: this.itemsComputed[this.cIndex].stockItemId,
        stockDeliveryId: item.stockDeliveryId,
        claimsApplyQuantity: this.dialog.actualQuantity,
        deliveryQuantity: this.dialog.actualQuantity,
        status: "STAGING",
        stockSite: this.dialog.stockSite,
        warehouseStockRackId: this.dialog.warehouseStockRackId
      };
      let placeholder = {
        id: item.id
      };
      console.log(123, params, placeholder);
      return this.$api.setStockDeliveryItems({ params, placeholder });
    },
    dialogCancel() {
      this.dialog = cloneDeep(this.dialogCopy);
      this.$refs.dialogForm.resetValidation();
      this.showDialog = false;
    },

    save(oper) {
      let curStagingStockSites = this.getCurStockSites();
      let allStockSites = this.getAllStockSites().filter(item => item.pickStatus == "STAGING" && item.stockDeliveryId != this.stockDeliveryId);
      let curStagingIds = [],
        curStockDeliveryIds = [],
        curStatus;
      let allStagingIds = [],
        allStockDeliveryIds = [],
        allStatus;
      let promise1 = [],
        promise2 = [];
      if (curStagingStockSites.length) {
        curStagingIds = curStagingStockSites.map(item => item.id);
        curStockDeliveryIds = [...new Set(curStagingStockSites.map(item => item.stockDeliveryId))];
        if (oper == 1 || oper == 2) {
          curStatus = "SUBMITTED";
        } else if (oper == 3) {
          curStatus = "STAGING";
        }
        console.log(curStagingIds, curStockDeliveryIds);
        if (curStagingIds.length) {
          promise1.push(this.setStockDeliveryItemStatus(curStagingIds, curStatus));
        }
        if (curStockDeliveryIds.length) {
          promise1.push(this.setStockDeliveryStatus(curStockDeliveryIds, curStatus));
        }
      }

      if (allStockSites.length) {
        allStagingIds = allStockSites.map(item => item.id);
        allStockDeliveryIds = [...new Set(allStockSites.map(item => item.stockDeliveryId))];
        allStagingIds = allStagingIds.filter(item => {
          return !curStagingIds.includes(item);
        });
        allStockDeliveryIds = allStockDeliveryIds.filter(item => {
          return !curStockDeliveryIds.includes(item);
        });
        if (oper == 1 || oper == 2) {
          allStatus = "SUBMITTED";
        } else if (oper == 3) {
          allStatus = "STAGING";
        }
        if (allStagingIds.length) {
          promise2.push(this.setStockDeliveryItemStatus(allStagingIds, allStatus));
        }
        if (allStockDeliveryIds.length) {
          promise2.push(this.setStockDeliveryStatus(allStockDeliveryIds, allStatus));
        }
      }

      if (oper == 2) {
        this.setClaimsApplyPicking("FINISHED");
      }

      let promise = promise1.concat(promise2);

      if (promise.length) {
        Promise.all(promise)
          .then(ret => {
            let result = ret.every(item => Object.prototype.toString.call(item) == "[object Object]" && item.code == SUCCESS_CODE);
            if (result) {
              setTimeout(async () => {
                await this.$router.go(-1);

                setTimeout(() => {
                  window.scrollTo(0, 0);
                  this.$parent.$refs[window.currentRoute].init();
                }, 500);
              }, 750);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    getCurStockSites() {
      return this.itemsComputed
        .filter(item => item.warehouseId == this.firstWarehouseId)
        .flatMap(item => item.stockSites)
        .filter(item => item.pickStatus == "STAGING");
    },
    getAllStockSites() {
      return this.detail.items.reduce((prev, cur) => {
        return prev.concat(cur.stockSites);
      }, []);
    },
    mapWarehouse(arg) {
      let warehouse = this.warehouseColumns.find(item => item.id == arg);
      return warehouse ? warehouse.name : "";
    }
  },
  filters: {
    parseStatus(arg) {
      if (arg == "SUBMITTED") {
        return "已出库";
      }
      if (arg == "STAGING") {
        return "未出库";
      }
    }
  },
  created() {
    this.dialogCopy = cloneDeep(this.dialog);
    this.getWarehouseList();
    if (this.$route.params.id) {
      this.getClaimApplyDetail();
    }
    if (this.$route.query.isDisable) {
      this.disabled = true;
    }
  }
};
</script>
